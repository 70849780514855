:root {
  /* red/burgundy color of buttons */
  --main-color: #bb3164;

  /* blue color of logo and text */
  --blue-color: #2878be;

  /* body background color */
  --body-color: #FAFAFA;

  /* charcoal color */
  --charcoal-color: #36454f;

  /* green for check mark */
  --green-color: #20c997;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');


body {
  background-color: var(--body-color);
  /*  overflow-x: hidden !important;*/
  overflow: hidden;
}

.greenTick {
  padding-left: 5px;
}


.MuiTooltip-tooltip {
  font-size: 1em !important;
}

.MuiAlertTitle-root {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.MuiAlert-message {
  font-size: 1.5em !important;
  font-weight: 100 !important;
}


.password-message {
  color: var(--main-color);
}

.overflow-transactions::-webkit-scrollbar {
  width: 0 !important;
}

.overflow-transactions {
  overflow-y: auto !important;
  height: 70vh;
}

h2, h1, .user-logged-in, .password-message {
  text-align: center;
}

h3, h4, h5 {
  margin-top: 3rem;
}

h4 {
  font-weight: 600 !important;
}

h5 {
  font-size: 1.8rem !important;
}


.account-retrieved-title {
  font-weight: 600;
}

.account-retrieved-fields {
  font-weight: 600;
}

.trail-border {
  background-color: var(--charcoal-color);
  color: white;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.trail-border>span {
  padding-left: 1rem;
}

.trail-text {
  font-size: 1em;
}

.lookup-not-found {
  font-weight: 600;
  color: var(--main-color);
}

.footer {
  box-sizing: border-box;
  /*
  background-color: rgba(54, 69, 79, 0.2);
  */
  max-width: 100%;
  width: 100%;
  font-size: 1rem;
  /*
  margin-top: 0px;
  */
  padding-top: 20px;
  padding-bottom: 20px;

}

.contact-footer {
  font-size: 1.3rem;
  font-weight: 700;
}

.footer div {
  text-align: center;
}

.cards-span {
  padding-left: 2em;
  vertical-align: middle;
}

.button-primary-link {
  text-decoration: none;
  appearance: button;
  padding: 0px 10px;
}

.button-primary {
  background-color: var(--main-color) !important;
  border: 0 solid yellow;
  border-radius: 30px;
}

button span {
  padding-left: 5px;
}

h4 span {
  padding-left: 5px;
}

textarea {
  height: 20vh !important;
}

button {
  appearance: button;
  border-radius: 20px;
}

button[disabled], button[disabled]:hover {
  background-color: #ccc !important;
  color: #fff !important;
}

.container {
  height: 100vh;
  background-color: white;
  padding-left: 20px !important;
  padding-right: 20px !important;
  /*
  overflow: hidden !important;
  */
}

.transaction-not-found {
  font-weight: 600;
}

.new-transaction {
  background-color: var(--main-color) !important;
  color: white;
}

.spinner {
  padding-left: 2rem;
}

.hidden {
  display: none !important;
}

.logo {
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 1vh;
}

* {
  /*  margin: 0;
  padding: 0;
  box-sizing: border-box;*/
  font-family: 'Poppins', sans-serif;
}

td {
  padding: 0;
  padding-bottom: 5px;
  padding-left: 10px;
}

.react-date-picker__inputGroup__day,
.react-date-picker__inputGroup__month {
  width: 3em !important;
}

.react-date-picker__inputGroup__year {
  width: 4em !important;
}

.react-date-picker__wrapper {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.Collapsible__contentOuter {}

.Collapsible__contentInner {
  padding-top: 5px;
  padding-left: 5px;

}


.Collapsible span {
  background-color: lightgrey;
  display: block;
  padding: 5px;
  border-radius: 10px;
}

.Collapsible {
  /*  background-color: rgb(198, 195, 195);*/
  padding: 5px;
  cursor: pointer;
}

.result-list {
  padding-left: 15px;
  display: inline-block !important;
}

.result-count {
  font-weight: 600;
  display: block;
}

.app-title {
  font-weight: 700;
}

.comment {
  display: none;
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.show {
  transition: opacity 3s ease-in-out;
  display: flex;
  background-color: black;
  color: white;
  padding: 10px;
  opacity: 1;
  margin-top: 5px;
  text-align: right;
}

/**** burger menu related ****/
.burger-menu__button {
  background: url('../images/domlec_ham_icon_inactive.png');
  border: none;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 1vw;
  top: 2vh;
  z-index: 2;
}


/* render the red burger menu image when menu is open */
.burger-menu--open .burger-menu__button {
  background: url('../images/domlec_ham_icon_close.png') !important;
  background-repeat: no-repeat !important;
}

.burger-menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 10px;
  position: absolute;
  top: 1vh;
  right: -100vw;
  width: 300px;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.menu-down-arrow {
  padding-left: 5px;
}

.menu-container-title {
  color: white;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}

.menu-container {
  display: block;
  background-color: rgba(54, 69, 79, 1);
  width: 100%;
  border-radius: 10px;
  height: auto;
  position: absolute;
  top: 11vh;
  right: 1vw;
}

.burger-menu--open .burger-menu__list {
  transform: translateX(-100vw);
}

/*
.burger-menu__list:not(.burger-menu--open) {
  visibility: hidden;
}
*/

.burger-menu__item {
  color: #fafafa;
  font-size: 2rem;
  padding: 12px;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.to-date-picker, .from-date-picker, .date-picker {
  width: 60%;
  padding: 1rem;
}

.login-button, .logout-button {
  margin: auto;
  margin-top: 2vh;
  display: block;
}

.login-failed {
  font-weight: 600;
  color: var(--main-color);
  text-align: center;
  display: block;
  padding-top: 2vh;
}

/* anything smaller than 550px*/
@media only screen and (max-width: 550px) {
  .logo {
    max-width: 75% !important;
    box-sizing: border-box;
    margin-top: 2vh;
  }

  .burger-menu__button {
    background-color: transparent;
    background: url('../images/domlec_ham_icon_inactive.png');
    border: none;
    cursor: pointer;
    height: 50px;

    position: absolute;
    right: 0vw;
    top: 2vh;
  }

  .container {
    /*
    height: 100vh;
    */
    background-color: white;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 95% !important;
  }

  .menu-container {
    display: block;
    background-color: rgba(54, 69, 79, 1);
    width: 100%;
    border-radius: 10px;
    height: auto;
    position: absolute;
    top: 11vh;
  }

  .burger-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 10px;
    position: absolute;
    top: 1vh;
    left: -100vw !important;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
  }

  .burger-menu--open .burger-menu__list {
    transform: translateX(100vw);
  }

}